import * as React from "react"
import ContentLayout from "../components/layouts/content"

const NotFoundPage = () => {
  const page = {
    title: "Page not found",
    description: "The page you are looking for is not here"
  }
  return (
    <ContentLayout page={page}>
      <title>Not found</title>
      <p className="text-center">Sorry no info here! We're searching all around the universe to get the answers so it will take a sec</p>
      <h1 className="text-center">
        <i className="fas fa-user-astronaut"></i>
      </h1>
    </ContentLayout>
  )
}

export default NotFoundPage
